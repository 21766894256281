#map {
    margin-top: 25px;
    width: 200px;
    height: 200px;
  }

figure {
  display: grid;
  justify-items: center;
  margin: 0;
}