.app-grid-container {
  display: grid;
  height: 100vh;
  grid: auto-flow min-content auto min-content / auto auto auto;
}

.app-grid-container header, footer {
  grid-column: 2;
  text-align: center;
  color: black;
  background-color: #B5B5B5;
}

.app-grid-container header {
  font-weight: bold;
  font-size: xx-large;
}

.app-grid-container header a {
  color: black;
}

.app-grid-container header a:hover {
  color: #FFFFFF;
}

.app-grid-container footer {
  width: 100%;
  height: 25px;
  bottom: 0;
}

.app-grid-container footer a {
  color: black;
}

.app-grid-container footer a:hover {
  color: #FFFFFF;
}

.app-grid-container main {
  grid-column: 2;
  overflow: auto;
  scrollbar-color: #91B59B #00000000;
}

.right-gutter {
  grid-column: 3;
}

.left-gutter {
  grid-column: 1;
}

.right-gutter, .left-gutter {
  height: 100%;
  width: 100%;
  grid-row: 1 / 4;
  background-color: #B5B5B5;
}
